import React from 'react'
import PropTypes from 'prop-types'

const Share = (props) => {
  const [shareUrl, setShareUrl] = React.useState('https://trexity.com')

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setShareUrl(window.location.href)
    }
  }, [])

  return (
    <ul className="social">
      <li><a href={`mailto:?body=${shareUrl}&subject=${props.shareTitle}`} target="_blank" rel="noreferrer"><img src="/img/social-email.png" alt="Email icon" /></a></li>
      <li><a href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${props.shareTitle}&source=LinkedIn`} target="_blank" rel="noreferrer"><img src="/img/social-linkedin.png" alt="Linked icon" /></a></li>
      <li><a href={`https://twitter.com/intent/tweet?text=${props.shareTitle}+${shareUrl}`} target="_blank" rel="noreferrer"><img src="/img/social-twitter.png" alt="Twitter icon" /></a></li>
      <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`} target="_blank" rel="noreferrer"><img src="/img/social-facebook.png" alt="Facebook icon" /></a></li>
    </ul>
  )
}

Share.propTypes = {
  shareTitle: PropTypes.string
}

export default Share
