import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'
import Config from '../../config'

export default function Coty2021 () {
  const shareTitle = 'Trexity announces Couriers of the year'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Trexity announces Couriers of the year</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='announcement'>Announcement</span>
                  <span>April 18, 2022</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <img src="/img/press/coty-awards-2021.jpg" alt="Couriers of the year 2021" />
                <p>Our Trexity Couriers mean the world to us, so each year we celebrate them with our <strong>Courier of the Year Award</strong>.</p>
                <p>Trexity knows that this past year has been particularly challenging for our Courier fleet. We truly appreciate the essential work they have done to support their community and all of our Trexity Merchants during these unprecedented times.</p>
                <p>Many Couriers were nominated, but these three Couriers (in their respective markets) were the best of the best. Let’s take a moment to learn more about them and celebrate their passions with a quick Q&amp;A</p>

                <h2>Yaacoub (Toronto)</h2>
                <img src="/img/press/coty-toronto-2021.jpg" alt="Yaacoub courier of the year" />
                <p className="mb-0"><strong>Q: Yaacoub, tell us what you love most about Toronto.</strong></p>
                <p className="mb">Toronto is a very loveable city, the merchants and customers are all friendly and positive.</p>

                <p className="mb-0"><strong>Q: Who is your favorite local merchant to do deliveries for?</strong></p>
                <p className="mb">I would say Stajj and Co because they are very friendly, it’s a good fare and customers tip very well. Another merchant I like is Social Blend, great pay and very nice customers!</p>

                <p className="mb-0"><strong>Q: Why choose to deliver with Trexity? What motivates you to keep delivering for us?</strong></p>
                <p className="mb">Trexity offered me some opportunities to earn great money guaranteed weekly since there were stable merchants on the platform such as Natural food pantry. What keeps me going is the great communication and relations I’ve developed with the Trexity support team!</p>

                <p className="mb-0"><strong>Q: How do you spend your spare time when you’re not driving?</strong></p>
                <p>I love to binge watch TV shows and am always keen to keep my fitness by working out.</p>

                <h2>Salah (Ottawa)</h2>
                <img src="/img/press/coty-ottawa-2021.jpg" alt="Salah courier of the year" />
                <p className="mb-0"><strong>Q: Salah, tell us what you love most about Ottawa.</strong></p>
                <p className="mb">It’s a really wonderful city. It’s a family focused city and is super well organized. </p>

                <p className="mb-0"><strong>Who is your favorite local merchant to do deliveries for?</strong></p>
                <p className="mb">Love, love love Jacobsons!</p>

                <p className="mb-0"><strong>Q: Why choose to deliver with Trexity? What motivates you to keep delivering for us?</strong></p>
                <p className="mb">Confidence in the Courier app and the support team is wonderful!! Trexity is growing everyday.</p>

                <p className="mb-0"><strong>Q: How do you spend your spare time when you’re not driving?</strong></p>
                <p className="mb">Gathering with my family is super important to me, and I also love watching movies.</p>

                <h2>Yijie (Winnipeg)</h2>
                <img src="/img/press/coty-winnipeg-2021.jpg" alt="Yijie courier of the year" />
                <p className="mb-0"><strong>Q: Yijie, tell us what you love most about Winnipeg.</strong></p>
                <p className="mb">The people of Winnipeg are always super friendly.</p>

                <p className="mb-0"><strong>Q: Who is your favorite local merchant to do deliveries for?</strong></p>
                <p className="mb">Nonsuch Brewing Co &amp; Gameknight Games, I deliver a ton for them, they’re great!</p>

                <p className="mb-0"><strong>Q: Why choose to deliver with Trexity? What motivates you to keep delivering for us?</strong></p>
                <p className="mb">The App works great, and Trexity is very quick to deliver payment.</p>

                <p className="mb-0"><strong>Q: How do you spend your spare time when you’re not driving?</strong></p>
                <p className="mb">I’m pretty much a homebody, I love to stay home and work on my special projects.</p>
              </div>

              <div style={{ borderTop: '1px solid #E5E5E5', paddingTop: 30, marginTop: 30 }}>
                <p>Thank you Yaacoub, Salah and Yijie, and all our Couriers, for being such an important part of the Trexity team.</p>
                <p><strong>We couldn’t have done this year without you!</strong></p>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/serving-up-customer-loyalty" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Serving up customer loyalty with subscriptions and rapid delivery.</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
