import React from 'react'
import PropTypes from 'prop-types'
import Layout from './layout'
import Mailchimp from './mailchimp'

const PressLayout = ({ children }) => {
  return (
    <Layout>
      {children}

      <section id="article-newsletter">
        <div className='row justify-center'>
          <div className='col text-center'>
            <div className="inner short">
              <h2>Trexity newsletter</h2>
              <Mailchimp />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

PressLayout.propTypes = {
  children: PropTypes.node
}

export default PressLayout
